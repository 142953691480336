<script>
import { formatPercent, formatAvg } from '@/lib/chart/format'

export default {
  name: 'LeaderboardBase',

  methods: {
    formatRating: formatAvg,

    formatRate(rate) {
      return !Number.isNaN(parseInt(rate)) ? formatPercent(rate) : '-'
    },

    roundOneDec(value) {
      return Number.isInteger(value) ? value : parseFloat(value.toFixed(1))
    },

    formatResponseTime(ms) {
      if (!ms) return '-'

      const duration = this.$moment.duration(ms)

      const days = duration.asDays()
      if (days >= 1) {
        const hrsLeft = Math.floor((days - Math.floor(days)) * 24)
        return `${Math.floor(days)} day${hrsLeft ? ` ${hrsLeft} hr` : ''}`
      }
      const hrs = duration.asHours()
      if (hrs >= 1) {
        const minsLeft = Math.floor((hrs - Math.floor(hrs)) * 60)
        return `${Math.floor(hrs)} hr${minsLeft ? ` ${minsLeft} min` : ''}`
      }
      const mins = duration.asMinutes()
      if (mins >= 1) {
        const secsLeft = Math.floor((mins - Math.floor(mins)) * 60)
        return `${Math.floor(mins)} min${secsLeft ? ` ${secsLeft} sec` : ''}`
      }
      const secs = duration.asSeconds()

      return Math.floor(secs) + ' sec'
    },

    locationName({ friendlyName, city }) {
      return friendlyName || city
    },

    uniquify(collection, value) {
      return value ? [...new Set([...collection, value])] : collection
    },

    sortCompare(aRow, bRow, key, sortDesc) {
      const a = aRow[key]
      const b = bRow[key]

      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // uncomment 3 lines below to ignore zero values in sorting
        // if (a === 0 && b === 0) return 0
        // if (a === 0) return sortDesc ? -1 : 1
        // if (b === 0) return sortDesc ? 1 : -1
        return a < b ? -1 : a > b ? 1 : 0
      } else if (this.toString(a) === null) {
        return sortDesc ? -1 : 1
      } else if (this.toString(b) === null) {
        return sortDesc ? 1 : -1
      } else {
        return this.toString(a).localeCompare(this.toString(b), undefined, {
          numeric: true,
        })
      }
    },

    toString(value) {
      if (value === null || typeof value === 'undefined' || value === 0) {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => this.toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },
  },
}
</script>
