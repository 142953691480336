import moment from 'moment'

export function buildSingleQuery({
  filters,
  dateRange,
  measure,
  timeDimension,
  granularity,
  timezone,
}) {
  return {
    filters,
    measures: [measure],
    timeDimensions: [
      {
        dimension: timeDimension,
        granularity,
        dateRange: formatDateRange(dateRange),
      },
    ],
    timezone,
  }
}

/**
 * summary section
 */
export function buildSummaryMetricsQuery({ companyIds, locationIds, dateRange, timezone }) {
  return [
    {
      mutexKey: 'customer-added',
      measures: ['Analyticevents.customerAdded'],
      timeDimensions: [
        { dimension: 'Analyticevents.created', dateRange: formatDateRange(dateRange) },
      ],
      filters: filterByCompaniesLocations({
        domain: 'Analyticevents',
        companyIds,
        locationIds,
      }),
      timezone,
    },
    {
      mutexKey: 'survey-metrics',
      measures: [
        'Surveys.completed',
        'Surveys.feedbacks',
        'Surveys.satisfaction',
        'Surveys.resolved',
      ],
      filters: filterByCompaniesLocations({
        domain: 'Surveys',
        companyIds,
        locationIds,
      }),
      timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
      timezone,
    },
  ]
}

export function buildCustomersQuery({ locationIds, companyIds, ...rest }) {
  const filters = filterByCompaniesLocations({
    domain: 'Analyticevents',
    companyIds,
    locationIds,
  })

  return buildSingleQuery({ ...rest, timeDimension: 'Analyticevents.created', filters })
}

export function buildSurveysQuery({ locationIds, companyIds, ...rest }) {
  const filters = filterByCompaniesLocations({
    domain: 'Surveys',
    companyIds,
    locationIds,
  })

  return buildSingleQuery({ ...rest, timeDimension: 'Surveys.created', filters })
}

/**
 * satisfaction section
 */
export function buildSatisfactionDaypartsQuery({
  companyIds,
  locationIds,
  dateRange,
  dayparts,
  timezone,
}) {
  const dimensions = ['Surveys.weekday']
  const order = { 'Surveys.weekday': 'asc' }
  const timeDimensions = [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }]
  const commonFilters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })

  return [
    ...dayparts.map((d, i) => ({
      order,
      timezone,
      dimensions,
      timeDimensions,
      mutexKey: `daypart-${i}`,
      measures: ['Surveys.satisfaction'],
      filters: [
        ...commonFilters,
        {
          dimension: 'Surveys.dayTime',
          operator: 'gte',
          values: [formatDaypartsTime(d.start)],
        },
        {
          dimension: 'Surveys.dayTime',
          operator: 'lte',
          values: [formatDaypartsTime(d.end)],
        },
      ],
    })),
    {
      order,
      timezone,
      dimensions,
      timeDimensions,
      filters: commonFilters,
      mutexKey: 'surveys-completed',
      measures: ['Surveys.completed'],
    },
  ]
}

export function buildSatisfactionBySourceQuery({ locationIds, companyIds, dateRange, timezone }) {
  const filters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })

  return {
    filters,
    timezone,
    dimensions: ['Surveys.source'],
    measures: ['Surveys.satisfaction'],
    order: { 'Surveys.satisfaction': 'desc' },
    timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
  }
}

export function buildSourceQuery({ locationIds, companyIds, dateRange, timezone }) {
  const filters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })

  return {
    filters,
    limit: 5,
    timezone,
    dimensions: ['Surveys.source'],
    measures: ['Surveys.completed'],
    order: { 'Surveys.completed': 'desc' },
    timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
  }
}

/**
 * reputation section
 */
export function buildReputationMetricsQuery({
  companyIds,
  locationIds,
  dateRange,
  timezone,
  source,
  implementedLocations,
}) {
  const sourceFilter =
    source === 'all'
      ? []
      : [
          {
            dimension: 'Reviews.source',
            operator: 'equals',
            values: [source],
          },
        ]

  return {
    leftReviewsQuery: {
      timezone,
      mutexKey: 'left-reviews',
      measures: ['Analyticevents.leftReview'],
      timeDimensions: [
        { dimension: 'Analyticevents.created', dateRange: formatDateRange(dateRange) },
      ],
      filters: filterByCompaniesLocations({
        companyIds,
        locationIds,
        domain: 'Analyticevents',
      }),
    },
    monthlyReviewsQuery: implementedLocations.map((l) => ({
      timezone,
      mutexKey: `monthly-reviews-${l._id}`,
      measures: ['Reviews.monthlyCountAfter', 'Reviews.monthlyCountBefore'],
      timeDimensions: [{ dimension: 'Reviews.timestamp', dateRange: l.implementedDateRange }],
      filters: filterByCompaniesLocations({ domain: 'Reviews', companyIds, locationIds: [l._id] }),
    })),
    currentReviewsQuery: {
      timezone,
      mutexKey: 'current-reviews',
      dimensions: ['Platforms.source'],
      measures: ['Platforms.ratingAfter', 'Platforms.ratingBefore', 'Platforms.totalAfter'],
      filters: filterByCompaniesLocations({ domain: 'Platforms', companyIds, locationIds }),
    },
    overTimeRatingQuery: {
      timezone,
      mutexKey: 'over-time-rating',
      measures: ['Reviews.avgRating'],
      timeDimensions: [{ dimension: 'Reviews.timestamp', dateRange: formatDateRange(dateRange) }],
      filters: [
        ...filterByCompaniesLocations({ domain: 'Reviews', companyIds, locationIds }),
        ...sourceFilter,
      ],
    },
  }
}
export function buildOverTimeReviewsQuery({
  companyIds,
  locationIds,
  dateRange,
  timezone,
  source,
  granularity,
}) {
  const locationFilter = filterByCompaniesLocations({ domain: 'Reviews', companyIds, locationIds })
  const sourceFilter =
    source === 'all'
      ? []
      : [
          {
            dimension: 'Reviews.source',
            operator: 'equals',
            values: [source],
          },
        ]

  return {
    timezone,
    filters: [...locationFilter, ...sourceFilter],
    measures: ['Reviews.avgRating', 'Reviews.count'],
    timeDimensions: [
      { dimension: 'Reviews.timestamp', dateRange: formatDateRange(dateRange), granularity },
    ],
  }
}

/**
 * marketing section
 */
export function buildMarketingMetricsQuery({ companyIds, locationIds, dateRange, timezone }) {
  return [
    {
      timezone,
      mutexKey: 'redeem-promo',
      measures: ['Analyticevents.redeemPromo'],
      timeDimensions: [
        { dimension: 'Analyticevents.created', dateRange: formatDateRange(dateRange) },
      ],
      filters: filterByCompaniesLocations({ domain: 'Analyticevents', companyIds }),
    },
    {
      timezone,
      mutexKey: 'text-usage',
      measures: ['Textmessageusageevents.totalUsage'],
      timeDimensions: [
        { dimension: 'Textmessageusageevents.createdat', dateRange: formatDateRange(dateRange) },
      ],
      filters: filterByCompaniesLocations({ domain: 'Textmessageusageevents', companyIds }),
    },
  ]
}

/**
 * Leaderboard
 */
export function buildLeaderboardLocationsQuery({ locationIds, companyIds, dateRange, timezone }) {
  const surveysfilters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })
  const reviewsfilters = filterByCompaniesLocations({ domain: 'Reviews', companyIds, locationIds })

  return [
    {
      timezone,
      filters: surveysfilters,
      mutexKey: 'leaderboard-locations-survey',
      dimensions: ['Surveys.location'],
      measures: [
        'Surveys.responses',
        'Surveys.completed',
        'Surveys.satisfaction',
        'Surveys.responseRate',
        'Surveys.avgResponseTime',
      ],
      timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
    },
    {
      timezone,
      filters: reviewsfilters,
      mutexKey: 'leaderboard-locations-rating',
      dimensions: ['Reviews.location'],
      measures: ['Reviews.avgRating', 'Reviews.count'],
      timeDimensions: [{ dimension: 'Reviews.timestamp', dateRange: formatDateRange(dateRange) }],
    },
  ]
}

export function buildLeaderboardFeedbacksQuery({ locationIds, companyIds, dateRange, timezone }) {
  const surveysfilters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })

  return {
    timezone,
    filters: surveysfilters,
    mutexKey: 'leaderboard-locations-feedbacks',
    dimensions: ['Surveys.location'],
    measures: ['Surveys.feedbacks'],
    timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
  }
}

export function buildLeaderboardEmployeesQuery({ locationIds, companyIds, dateRange, timezone }) {
  const surveysfilters = filterByCompaniesLocations({ domain: 'Surveys', companyIds, locationIds })
  const analyticsfilters = filterByCompaniesLocations({
    domain: 'Analyticevents',
    companyIds,
    locationIds,
  })

  return [
    {
      timezone,
      filters: surveysfilters,
      mutexKey: 'leaderboard-employees-survey',
      dimensions: ['Surveys.responseBy'],
      measures: ['Surveys.responses', 'Surveys.avgResponseTime'],
      timeDimensions: [{ dimension: 'Surveys.created', dateRange: formatDateRange(dateRange) }],
    },
    {
      timezone,
      filters: analyticsfilters,
      dimensions: ['Analyticevents.user'],
      measures: ['Analyticevents.feedbackOpened'],
      mutexKey: 'leaderboard-employees-feedback-opened',
      timeDimensions: [
        { dimension: 'Analyticevents.created', dateRange: formatDateRange(dateRange) },
      ],
    },
  ]
}

/**
 *
 * utils
 */

export function formatDateRange(dateRange) {
  if (Array.isArray(dateRange)) {
    return dateRange
  }

  const today = moment().format('YYYY-MM-DD')
  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD')

  switch (dateRange) {
    case 'Today':
      return [today, tomorrow]
    case 'Last 7 days':
      return [moment().subtract(7, 'day').format('YYYY-MM-DD'), tomorrow]
    case 'Last 30 days':
      return [moment().subtract(30, 'd').format('YYYY-MM-DD'), tomorrow]
    case 'Last quarter':
      return [moment().subtract(4, 'months').format('YYYY-MM-DD'), tomorrow]
    case 'Last year':
      return [moment().subtract(1, 'year').format('YYYY-MM-DD'), tomorrow]
    case 'This week':
      return [moment().startOf('week').add(1, 'day').format('YYYY-MM-DD'), tomorrow]
    case 'This month':
      return [moment().startOf('month').format('YYYY-MM-DD'), tomorrow]
    default:
      return dateRange
  }
}

function formatDaypartsTime(time) {
  const date = moment(time, 'h:mm A')
  return (date.hour() * 60 + date.minute()).toString()
}

function filterByCompaniesLocations({ domain, field, companyIds, locationIds }) {
  return [
    ...filterByCompanies({ domain, field, companyIds }),
    ...filterByLocations({ domain, field, locationIds }),
  ]
}

function filterByCompanies({ domain, field = 'company', companyIds = [] }) {
  if (!companyIds.length) {
    return []
  }

  return [
    {
      dimension: `${domain}.${field}`,
      operator: 'equals',
      values: companyIds,
    },
  ]
}

function filterByLocations({ domain, field = 'location', locationIds = [] }) {
  if (!locationIds.length) {
    return []
  }

  return [
    {
      dimension: `${domain}.${field}`,
      operator: 'equals',
      values: locationIds,
    },
  ]
}
