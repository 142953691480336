<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center justify-content-center">
        <div class="col">
          <h1 class="header-title mt-4 mb-2">Settings</h1>
        </div>
        <div class="col-auto">
          <FadeTransition>
            <div
              v-if="
                $route.name === 'company-settings-team' &&
                (onlineUser.role === 'admin' || onlineUser.role === 'location-manager')
              "
              class="col-auto"
            >
              <button class="btn btn-outline-primary" v-b-modal.inviteEmployeeModal>
                <span class="fe fe-plus"></span>
                Invite
              </button>
            </div>
          </FadeTransition>
        </div>
      </div>
      <CompanySettingsNavbar v-on:open-invite-modal="$emit('open-invite-modal')" />
    </div>
  </div>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import CompanySettingsNavbar from './CompanySettingsNavbar'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'CompanySettingsHeader',
  components: {
    FadeTransition,
    CompanySettingsNavbar,
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
    ...CompanyModule.mapGetters(['activePartnerName']),
  },
}
</script>
