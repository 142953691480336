<template>
  <MainContent>
    <CompanySettings :key="selectActiveCompany?._id" />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CompanySettings from '@/components/Modules/Company/CompanySettings/CompanySettings'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanyPage',
  components: {
    MainContent,
    CompanySettings,
  },
  mounted() {
    trackEvent(this.$intercom, 'Company settings')
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
  },
}
</script>

<style scoped lang="scss"></style>
