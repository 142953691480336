<template>
  <div>
    <!-- highscores -->
    <div class="row">
      <div v-for="h in highscoresFields" :key="h.key" class="col-sm-12 col-md-6 col-lg-4">
        <div class="card text-center">
          <div class="card-header flex-column">
            <i :class="[h.icon, 'fe highscore-icon text-secondary']" />
            <h3 class="mb-2 text-primary text-center">{{ h.title }}</h3>
          </div>
          <div v-if="status === 'loaded'" class="card-body">
            <template v-if="highscores[h.key].length">
              <div class="avatar-group">
                <a
                  v-for="k in highscores[h.key].slice(0, 6)"
                  :key="k.id"
                  href="javascript:;"
                  class="mb-2 avatar avatar-sm"
                  v-b-tooltip.hover="k.name"
                >
                  <img
                    v-if="k.avatar.img"
                    :src="k.avatar.img"
                    style="border: 1px solid #fff"
                    class="avatar-img rounded-circle"
                  />
                  <span
                    v-else
                    style="border: 1px solid #fff"
                    class="avatar-title rounded-circle text-uppercase"
                    >{{ k.avatar.initial }}</span
                  >
                </a>
              </div>
              <h4 v-if="highscores[h.key].length === 1" class="m-0">
                {{ highscores[h.key][0].name }}
              </h4>
              <h4 v-else class="m-0">Multiple Employees</h4>
            </template>
            <span v-else class="text-muted">Not enough data!</span>
          </div>
          <div v-else class="card-body">
            <b-skeleton animation="fade" class="m-auto" width="40px" height="40px" />
            <b-skeleton animation="fade" class="mb-0 mt-2 mx-auto" width="100px" height="18px" />
          </div>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h2 class="m-0">Employees</h2>
            <div v-if="locations.length > 1" class="flex-grow-0">
              <MultiSelect
                multiple
                id="leaderboard-locations"
                :close-on-select="false"
                :clear-on-select="false"
                :custom-label="locationName"
                track-by="_id"
                :show-labels="false"
                openDirection="below"
                :limitText="(count) => `+${count} more`"
                :limit="1"
                placeholder="Search locations"
                showNoResults
                tagPosition="bottom"
                v-model="selectedLocations"
                :options="locations"
                style="min-width: 300px; height: 40px"
              >
                <template #placeholder
                  ><span><span class="fe fe-map-pin mr-2"></span>All locations</span></template
                >
                <template #option="{ option }">
                  <div class="d-flex flex-column">
                    <span>{{ locationName(option) }}</span>
                    <small
                      v-if="option.addressDetails && option.addressDetails.streetNumber"
                      class="text-muted small text-truncate"
                      >{{
                        `${option.addressDetails.streetNumber} ${option.addressDetails.streetName}`
                          | truncate(20)
                      }}</small
                    >
                  </div>
                </template>
                <template #noResult><span>No matching locations.</span></template>
              </MultiSelect>
            </div>
          </div>
          <b-table
            v-if="status === 'loaded'"
            small
            foot-clone
            responsive
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-compare="sortCompare"
            :table-class="['card-table', 'table-nowrap']"
          >
            <template #head()="{ label, field: { key, sortable, tooltip } }">
              <span>
                {{ label }}
                <i
                  v-if="tooltip"
                  v-b-tooltip.hover.top
                  :title="tooltip"
                  class="fas text-muted ml-1 fa-info-circle cursor-pointer"
                ></i
              ></span>
              <template v-if="sortable">
                <template v-if="sortBy !== key">
                  <i class="fe fe-arrow-down"></i>
                  <i class="fe fe-arrow-up"></i>
                </template>
                <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
                <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
              </template>
            </template>
            <template #cell(avatar)="{ value }">
              <div class="avatar avatar-xs">
                <img v-if="value.img" :src="value.img" class="avatar-img rounded-circle" />
                <span v-else class="avatar-title rounded-circle text-uppercase">{{
                  value.initial
                }}</span>
              </div>
            </template>
            <template #cell(name)="{ value }">
              <span :title="value">{{ value }}</span>
            </template>
            <template #cell(locations)="{ value }">
              <div class="d-flex">
                <div class="d-flex location-badge" :title="value.join(', ')">
                  <span
                    :key="l + i"
                    v-for="(l, i) of value"
                    style="font-size: 13px"
                    class="badge badge-light mr-2"
                    >{{ l }}</span
                  >
                </div>
              </div>
              <span v-if="!value.length">All locations</span>
            </template>
            <template #cell(usedApp)="{ value }">
              <CheckOrX class="text-large" :value="value" />
            </template>
            <template #cell(openRate)="{ value }">
              <i
                v-if="openRateTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value === openRateTop3[0],
                  silver: value === openRateTop3[1],
                  bronze: value === openRateTop3[2],
                }"
              />
              {{ formatRate(value) }}
            </template>
            <template #cell(responseRate)="{ value, item }">
              <i
                v-if="responseRateTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value === responseRateTop3[0],
                  silver: value === responseRateTop3[1],
                  bronze: value === responseRateTop3[2],
                }"
              />
              {{ formatRate(value) }}
              <span class="ml-1" v-if="item.responses">({{ item.responses }})</span>
            </template>
            <template #cell(responseTime)="{ value }">
              <i
                v-if="responseTimeTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value && value === responseTimeTop3[0],
                  silver: value && value === responseTimeTop3[1],
                  bronze: value && value === responseTimeTop3[2],
                }"
              />
              {{ formatResponseTime(value) }}
            </template>
            <template #foot(responseRate)>
              <span class="footer-th"
                >{{ overall.responseRate }}
                <span class="ml-1" v-if="overall.responses">({{ overall.responses }})</span></span
              >
            </template>
            <template #foot()="{ field }">
              <span class="footer-th">{{ overall[field.key] }}</span>
            </template>
          </b-table>
          <div v-else class="mb--4">
            <b-skeleton-table :columns="6" :rows="noOfEmployees" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, createNamespacedHelpers } from 'vuex'

import { loadSeries } from '@/lib/cubejs'
import LeaderboardBase from './LeaderboardBase'
import { formatPivotTable } from '@/lib/chart/format'
import MultiSelect from '@/components/Common/MultiSelect'
import CheckOrX from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/CheckOrX'
import {
  buildLeaderboardEmployeesQuery,
  buildLeaderboardFeedbacksQuery,
  formatDateRange,
} from '@/lib/chart/queries'

const ConversationModule = createNamespacedHelpers('conversationV2')
const UserModule = createNamespacedHelpers('user')

// @group Leaderboard
// This component displays Employees leaderboard data on the main leaderboard page
export default {
  name: 'EmployeesSection',

  extends: LeaderboardBase,

  components: {
    CheckOrX,
    MultiSelect,
  },

  props: ['filters', 'locations', 'activeCompanyId'],

  data() {
    return {
      selectedLocations: [],
      highscoresFields: [
        { key: 'openRate', title: 'Best Open Rate', icon: 'fe-eye' },
        { key: 'responseRate', title: 'Best Response Rate', icon: 'fe-refresh-ccw' },
        { key: 'responseTime', title: 'Fastest Response Time', icon: 'fe-clock' },
      ],
      highscores: {
        openRate: [],
        responseRate: [],
        responseTime: [],
      },
      sortDesc: true,
      sortBy: 'openRate',
      overall: {},
      fields: [
        { key: 'avatar', label: '', sortable: false, class: ['avatar-td', 'align-middle'] },
        { key: 'name', sortable: true, class: ['align-middle', 'name-td'] },
        { key: 'locations', sortable: false, class: ['align-middle', 'location-td'] },
        { key: 'usedApp', sortable: false, class: ['text-center', 'align-middle'] },
        {
          key: 'openRate',
          sortable: true,
          class: 'align-middle',
          tooltip: 'The percentage of feedback from their location(s) that the employee viewed',
        },
        {
          key: 'responseRate',
          sortable: true,
          class: 'align-middle',
          tooltip:
            'The percentage of feedback received during the selected time period that was responded to by the employee',
        },
        {
          key: 'responseTime',
          sortable: true,
          class: 'align-middle',
          sortByFormatted: false,
          tooltip:
            'The average time the given employee responded to feedback during the selected time period',
        },
      ],
      items: [],
      status: 'loading',
      openRateTop3: [],
      responseRateTop3: [],
      responseTimeTop3: [],
    }
  },

  computed: {
    ...mapState('user', ['onlineUser']),
    ...ConversationModule.mapGetters(['selectOpenRates']),
    ...UserModule.mapGetters(['selectUserMap']),
    locationIds() {
      const locations = this.selectedLocations.length ? this.selectedLocations : this.locations

      return locations.map((l) => l._id)
    },
    filteredEmployees() {
      const self = this
      return Object.values(this.selectUserMap)
        .filter((e) =>
          e.locations?.length ? e.locations.some((l) => self.locationIds.includes(l)) : true
        )
        .map((e) => {
          const locations = e.role === 'admin' ? self.locationIds : e.locations
          return {
            ...e,
            locations: locations
              .filter((l) => self.locationIds.includes(l))
              .map((l) => self.locations.find((loc) => loc._id === l)),
          }
        })
    },
    noOfEmployees() {
      return this.filteredEmployees.length || 3
    },
  },

  watch: {
    async locations() {
      const filters = { ...this.filters, locationIds: this.locationIds }

      await this.loadTable(filters)
    },
    async locationIds(locationIds) {
      if (this.status === 'loading') return
      const filters = { ...this.filters, locationIds }
      await this.loadTable(filters)
    },
    selectUserMap: {
      deep: true,
      immediate: true,
      async handler() {
        const filters = { ...this.filters, locationIds: this.locationIds }

        await this.loadTable(filters)
      },
    },
  },

  methods: {
    ...ConversationModule.mapActions(['fetchOpenRates']),
    async loadTable(filters) {
      this.status = 'loading'
      try {
        const items = []
        const queries = [
          ...buildLeaderboardEmployeesQuery(filters),
          buildLeaderboardFeedbacksQuery(filters),
        ]
        const [resultSets, openRatesV2] = await Promise.all([
          loadSeries(queries),
          this.fetchOpenRates({
            companyIds: this.filters.companyIds,
            dateRange: formatDateRange(this.filters.dateRange),
          }),
        ])
        const [surveysData, openedData, feedbacksData] = resultSets.map(formatPivotTable)
        const feedbacksByLocations = this.getLocationsFeedbacks(feedbacksData)
        let totalUsedApp = 0,
          totalOpenRate = 0,
          totalResponses = 0,
          totalResponseRate = 0,
          totalResponseTime = 0,
          openRates = [],
          responseRates = [],
          responseTimes = []

        this.openRateTop3 = []
        this.responseRateTop3 = []
        this.responseTimeTop3 = []

        this.filteredEmployees.forEach(
          ({
            role,
            avatar,
            firstName = '',
            lastName = '',
            locations = [],
            pushTokens = [],
            _id: employeeId,
          }) => {
            const initial = firstName[0] + lastName[0]
            const name = [firstName, lastName].join(' ').trim()
            const usedApp = pushTokens && pushTokens.length > 0
            const {
              ['Surveys.responses']: responses = 0,
              ['Surveys.avgResponseTime']: responseTime = 0,
            } = surveysData.find((s) => s['Surveys.responseBy'] === employeeId) || {}
            const totalFeedbacks = feedbacksByLocations(locations)
            const responseRate = this.getResponseRate(totalFeedbacks, responses)
            const openRate = this.getOpenRate(employeeId)

            openRates = this.uniquify(openRates, openRate)
            responseRates = this.uniquify(responseRates, responseRate)
            responseTimes = this.uniquify(responseTimes, responseTime)

            totalOpenRate += openRate
            totalResponses += responses
            totalUsedApp += usedApp ? 1 : 0
            totalResponseRate += responseRate
            totalResponseTime += responseTime

            items.push({
              name,
              usedApp,
              responses,
              openRate,
              responseTime,
              responseRate:
                totalFeedbacks > 0 && responses === 0
                  ? 0
                  : totalFeedbacks === 0
                  ? null
                  : responseRate,
              avatar: { img: avatar, initial },
              locations: role === 'admin' ? [] : locations.map(this.locationName),
            })
          }
        )
        this.openRateTop3 = openRates.sort((a, b) => b - a).slice(0, 3)
        this.responseRateTop3 = responseRates.sort((a, b) => b - a).slice(0, 3)
        this.responseTimeTop3 = responseTimes.sort((a, b) => a - b).slice(0, 3)
        ;[
          ['openRate', openRates[0]],
          ['responseRate', responseRates[0]],
          ['responseTime', responseTimes[0]],
        ].forEach(([key, topScore]) => {
          this.$set(
            this.highscores,
            key,
            items.filter((i) => i[key] === topScore)
          )
        })
        this.items = items
        this.overall = {
          locations: '',
          name: 'Overall',
          responses: totalResponses,
          openRate: this.formatRate(totalOpenRate / openedData.length),
          usedApp: this.formatRate((totalUsedApp / this.noOfEmployees) * 100),
          responseRate: this.formatRate(totalResponseRate / surveysData.length),
          responseTime: this.formatResponseTime(totalResponseTime / surveysData.length),
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.status = 'loaded'
      }
    },

    getLocationsFeedbacks(feedbacksData) {
      const feedbacksMap = feedbacksData.reduce(
        (acc, cur) => ({ ...acc, [cur['Surveys.location']]: cur['Surveys.feedbacks'] }),
        {}
      )
      return (locations) => locations.reduce((acc, l) => acc + (feedbacksMap[l._id] || 0), 0)
    },

    getOpenRate(employeeId) {
      const openRate = this.selectOpenRates[employeeId] || 0
      return openRate * 100
    },

    getResponseRate(totalFeedbacks, responses) {
      if (!responses) return 0

      const rate = (responses / totalFeedbacks) * 100
      return rate > 100 ? 100 : this.roundOneDec(rate)
    },
  },
}
</script>

<style lang="scss" scoped>
.gold {
  color: #d7a032;
}
.silver {
  color: #687a88;
}
.bronze {
  color: #b5603b;
}
.card-body {
  padding: 16px !important;
}
.chart-nav {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #f9fbfd;
}
.highscore-icon {
  font-size: 25px;
  line-height: 1;
  margin: 5px;
}
::v-deep .table-responsive {
  margin: 0;
}
::v-deep .table-sm td,
.footer-th {
  font-size: 14px;
}
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
::v-deep .avatar-td {
  width: 27px;
}
::v-deep .location-td {
  max-width: 200px;
  cursor: default;
}
::v-deep .name-td {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.location-badge {
  margin: 0;
  max-width: 200px;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.location-badge::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
