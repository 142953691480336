<template>
  <div class="row align-items-center">
    <div class="col">
      <ul class="nav nav-tabs nav-overflow header-tabs">
        <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
          <router-link
            :to="{ name: navItem.route }"
            class="nav-link"
            :class="{ active: navItem.route === activeSettingsTab }"
            >{{ navItem.title }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanySettingsNavbar',
  computed: {
    ...mapState('company', ['activeSettingsTab']),
    ...mapState('user', ['onlineUser']),
    ...mapGetters('navigation', ['companyNavigation', 'selectCTTAllowedNavigation']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    hasPermissionForResponses() {
      try {
        const user = this.onlineUser
        const { permissions } = this.selectActiveCompany || {}
        if (user?.role === 'admin') return true
        if (!permissions) return true
        const { automatedResponseWriteAccess, automatedResponseWriteAccessUsers } = permissions
        if (!automatedResponseWriteAccess && !automatedResponseWriteAccessUsers) return true

        if (automatedResponseWriteAccessUsers?.length) {
          return automatedResponseWriteAccessUsers.includes(user?._id)
        }

        if (automatedResponseWriteAccess?.length) {
          return automatedResponseWriteAccess.includes(user?.role)
        }

        return true
      } catch (ex) {
        return true
      }
    },

    navItems() {
      let filteredNavigation = this.companyNavigation
      if (!this.hasPermissionForResponses) {
        filteredNavigation = filteredNavigation.filter(
          (navItem) => navItem.route !== 'company-settings-responses'
        )
      }
      return this.selectActiveCompany?.settings?.cttOnly
        ? filteredNavigation.filter((cn) => this.selectCTTAllowedNavigation.includes(cn.route))
        : filteredNavigation
    },
  },
}
</script>

<style scoped lang="scss"></style>
