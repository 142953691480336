<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <CompanySettingsHeader />
        <SlideFadeTransition v-if="initialized">
          <router-view />
        </SlideFadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySettingsHeader from './CompanySettingsHeader'
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'CompanySettings',
  components: {
    CompanySettingsHeader,
    SlideFadeTransition,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    this.markInitialized()
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    markInitialized() {
      this.initialized = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
