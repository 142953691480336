<template>
  <div>
    <!-- highscores -->
    <div class="row">
      <div v-for="h in highscoresFields" :key="h.key" class="col-sm-12 col-md-6 col-lg-4">
        <div class="card text-center">
          <div class="card-header flex-column">
            <i :class="[h.icon, 'fe highscore-icon text-secondary']" />
            <h3 class="mb-2 text-primary text-center">{{ h.title }}</h3>
          </div>
          <div v-if="status === 'loaded'" class="card-body">
            <template v-if="highscores[h.key].length">
              <div class="avatar-group">
                <a
                  v-for="k in highscores[h.key].slice(0, 6)"
                  :key="k.id"
                  href="javascript:;"
                  class="mb-2 avatar avatar-sm"
                  v-b-tooltip.hover="k.name"
                >
                  <img
                    class="avatar-img rounded"
                    style="border: 1px solid #fff"
                    :src="k.photo"
                    :alt="k.name"
                  />
                </a>
              </div>
              <h4 v-if="highscores[h.key].length === 1" class="m-0">
                {{ highscores[h.key][0].name }}
              </h4>
              <h4 v-else class="m-0">Multiple Locations</h4>
            </template>
            <span v-else class="text-muted">Not enough data!</span>
          </div>
          <div v-else class="card-body">
            <b-skeleton animation="fade" class="m-auto" width="40px" height="40px" />
            <b-skeleton animation="fade" class="mb-0 mt-2 mx-auto" width="100px" height="18px" />
          </div>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h2 class="m-0 d-flex align-items-center">Locations</h2>
            </div>
          </div>
          <b-table
            v-if="status === 'loaded'"
            small
            foot-clone
            responsive
            sort-null-last
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-compare="sortCompare"
            fixed
            @row-clicked="
              (item) =>
                (item.addressDetailsId && itemsByAddress[item.addressDetailsId].length > 1) ||
                (item.children && item.children.length)
                  ? $set(item, '_showDetails', !item._showDetails)
                  : null
            "
            :table-class="['card-table', 'table-nowrap']"
          >
            <template #head()="{ label, field: { key, sortable, tooltip } }">
              <span>
                {{ label }}
                <i
                  v-if="tooltip"
                  v-b-tooltip.hover.top
                  :title="tooltip"
                  class="fas text-muted ml-1 fa-info-circle cursor-pointer"
                ></i>
              </span>
              <template v-if="sortable">
                <template v-if="sortBy !== key">
                  <i class="fe fe-arrow-down"></i>
                  <i class="fe fe-arrow-up"></i>
                </template>
                <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
                <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
              </template>
            </template>

            <template #cell(name)="{ item }">
              <div class="text-truncate" style="whitespace: nowrap">
                <i
                  v-if="
                    (item.addressDetailsId && itemsByAddress[item.addressDetailsId].length > 1) ||
                    (item.children && item.children.length)
                  "
                  :class="{
                    'fe fe-chevron-right': item._showDetails != true,
                    'fe fe-chevron-down': item._showDetails == true,
                  }"
                />
                {{ item.name }}
              </div>
            </template>

            <template #cell(satisfaction)="{ value }">
              <i
                v-if="satisfactionTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value === satisfactionTop3[0],
                  silver: value === satisfactionTop3[1],
                  bronze: value === satisfactionTop3[2],
                }"
              />
              {{ formatRating(value) }}
            </template>

            <template #cell(responseRate)="{ value }">
              <i
                v-if="responseRateTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value === responseRateTop3[0],
                  silver: value === responseRateTop3[1],
                  bronze: value === responseRateTop3[2],
                }"
              />
              {{ formatRate(value) }}
            </template>

            <template #cell(responseTime)="{ value }">
              <i
                v-if="responseTimeTop3.includes(value)"
                :class="{
                  'fa fa-crown': true,
                  gold: value === responseTimeTop3[0],
                  silver: value === responseTimeTop3[1],
                  bronze: value === responseTimeTop3[2],
                }"
              />
              {{ formatResponseTime(value) }}
            </template>

            <template #cell(onlineRating)="{ item }">
              {{ formatRating(item.onlineRating) }}
              <span
                class="text-muted h6"
                v-if="item.reviewCount"
                v-b-popover.hover.bottom="ratingMessage"
              >
                ({{ item.reviewCount }})
              </span>
            </template>

            <template #foot()="{ field }">
              <span class="footer-th">{{ overall[field.key][0] }}</span>
              <span
                v-if="overall[field.key][1]"
                class="text-muted h6"
                v-b-popover.hover.bottom="ratingMessage"
              >
                ({{ overall[field.key][1] }})
              </span>
            </template>

            <template slot="row-details" slot-scope="{ item }">
              <b-table
                small
                foot-clone
                fixed
                responsive
                sort-null-last
                :items="itemsByAddress[item.addressDetailsId]"
                :fields="fields"
                thead-class="agg-table-header"
                tfoot-class="agg-table-footer"
              >
                <template #cell(name)="{ item }">
                  <div class="text-truncate">
                    {{ item.companyName }}
                  </div>
                </template>
                <template #cell(satisfaction)="{ value }">
                  {{ formatRating(value) }}
                </template>

                <template #cell(responseRate)="{ value }">
                  {{ formatRate(value) }}
                </template>

                <template #cell(responseTime)="{ value }">
                  {{ formatResponseTime(value) }}
                </template>

                <template #cell(onlineRating)="{ item }">
                  {{ formatRating(item.onlineRating) }}
                  <span
                    class="text-muted h6"
                    v-if="item.reviewCount"
                    v-b-popover.hover.bottom="ratingMessage"
                  >
                    ({{ item.reviewCount }})
                  </span>
                </template>
              </b-table>
            </template>
          </b-table>

          <div v-else class="mb--4">
            <b-skeleton-table :columns="6" :rows="noOfLocations" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from '@/components/Common/MultiSelect'
import { loadSeries } from '@/lib/cubejs'
import LeaderboardBase from './LeaderboardBase'
import { formatPivotTable } from '@/lib/chart/format'
import { buildLeaderboardLocationsQuery } from '@/lib/chart/queries'
import { createNamespacedHelpers } from 'vuex'
import DashboardService from '../../../../services/DashboardService'
import { formatDateRange } from '@/lib/chart/queries'

const analyticsModule = createNamespacedHelpers('analytics')
const companyModule = createNamespacedHelpers('company')
const featureFlagModule = createNamespacedHelpers('featureFlag')

// @group Leaderboard
// This component displays Locations leaderboard data on the main leaderboard page
export default {
  name: 'LocationsSection',

  extends: LeaderboardBase,

  components: {
    MultiSelect,
  },

  props: ['filters', 'locations'],

  data() {
    return {
      highscoresFields: [
        {
          key: 'satisfaction',
          title: 'Highest Satisfaction',
          icon: 'fe-thumbs-up',
        },
        { key: 'responseRate', title: 'Best Response Rate', icon: 'fe-refresh-ccw' },
        { key: 'responseTime', title: 'Fastest Response Time', icon: 'fe-clock' },
      ],
      highscores: {
        satisfaction: [],
        responseRate: [],
        responseTime: [],
      },
      sortDesc: true,
      sortBy: 'surveysCompleted',
      overall: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'surveysCompleted',
          sortable: true,
          tooltip: 'The average survey score of surveys received during the selected time period.',
        },
        {
          key: 'satisfaction',
          sortable: true,
          tooltip: 'The average survey score of surveys received during the selected time period',
        },
        {
          key: 'responseRate',
          sortable: true,
          tooltip:
            'The percentage of feedback received during the selected time period that was responded to',
        },
        {
          key: 'responseTime',
          sortable: true,
          sortByFormatted: false,
          tooltip:
            'The average time it took for feedback to be responded to within the selected time period',
        },
        {
          key: 'onlineRating',
          sortable: true,
          tooltip: 'The average rating of online reviews received during the selected time period',
        },
      ],
      items: [],
      groupedItems: [],
      status: 'loading',
      satisfactionTop3: [],
      responseRateTop3: [],
      responseTimeTop3: [],
      itemsByAddress: {},
      ratingMessage: 'Total number of reviews for this location across all platforms.',
      externalReviews: [],
    }
  },

  computed: {
    ...analyticsModule.mapState({
      ratingsPerLocation: (state) => state.metrics.ratingsPerLocation,
    }),
    ...companyModule.mapGetters(['companies', 'activeCompany']),
    noOfLocations() {
      return this.locations.length || 3
    },
    ...featureFlagModule.mapGetters(['isEnabledV2']),
  },

  watch: {
    async locations() {
      await this.loadTable(this.filters)
    },
  },

  methods: {
    ...analyticsModule.mapActions(['updateMetrics']),
    async loadTable(filters) {
      this.status = 'loading'
      try {
        let items = []
        const queries = buildLeaderboardLocationsQuery(filters)
        const [resultSets, locationRatingAndCount] = await Promise.all([
          loadSeries(queries),
          DashboardService.locationRatingAndCount({
            filters: {
              ...filters,
              timestampRange: formatDateRange(filters.dateRange),
            },
          }),
        ])

        const [surveysData, reviewsData] = resultSets.map(formatPivotTable)

        this.satisfactionTop3 = []
        this.responseRateTop3 = []
        this.responseTimeTop3 = []
        this.itemsByAddress = {}
        let self = this
        this.locations.forEach((l) => {
          let {
            ['Surveys.completed']: surveysCompleted = 0,
            ['Surveys.satisfaction']: satisfaction = 0,
            ['Surveys.responseRate']: responseRate = 0,
            ['Surveys.avgResponseTime']: responseTime = 0,
            ['Surveys.responses']: surveysResponses = 0,
          } = surveysData.find((s) => s['Surveys.location'] === l._id) || {}

          let onlineRating = null
          let reviewCount = null

          onlineRating = locationRatingAndCount.body.data[l._id]?.averageRating
          reviewCount = locationRatingAndCount.body.data[l._id]?.count

          const id = l._id
          const name = this.locationName(l)
          const photo = l.primaryPhoto || '/img/avatars/profiles/store.svg'

          // check if there is a survey and responses
          if (surveysCompleted > 0 && surveysResponses === 0) {
            // set the response rate to 0
            responseRate = 0
          }
          // if surveys completed is 0, set the response rate null
          if (surveysCompleted === 0) {
            responseRate = null
          }

          let item = {
            id,
            name,
            photo,
            responseTime,
            responseRate,
            satisfaction,
            onlineRating,
            reviewCount,
            surveysCompleted,
            companyName: self.companies.find((company) => company._id == l.company).name,
          }
          if (l.addressDetails && this.activeCompany._id === 'ALL_COMPANIES') {
            if (!self.itemsByAddress[l.addressDetails._id]) {
              self.itemsByAddress[l.addressDetails._id] = []
            }
            item.addressDetailsId = l.addressDetails._id
            self.itemsByAddress[l.addressDetails._id].push(item)
          } else {
            items.push(item)
          }
        })

        if (this.activeCompany._id === 'ALL_COMPANIES') {
          for (var addressId in this.itemsByAddress) {
            let item = { ...this.itemsByAddress[addressId][0] }
            let surveysCompletedAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + b.surveysCompleted
            }, 0)
            item.surveysCompleted = surveysCompletedAggregated

            let satisfactionAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + (b.satisfaction ? (b.satisfaction / 5) * b.surveysCompleted : 0)
            }, 0)
            item.satisfaction = 5 * (satisfactionAggregated / surveysCompletedAggregated)

            let responseRateAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + (b.responseRate !== null ? (b.responseRate / 100) * b.surveysCompleted : 0)
            }, 0)
            item.responseRate = (responseRateAggregated / surveysCompletedAggregated) * 100

            let responseTimeAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + (b.responseTime ? b.responseTime * b.surveysCompleted : 0)
            }, 0)
            item.responseTime = responseTimeAggregated / surveysCompletedAggregated

            let reviewCountAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + b.reviewCount
            }, 0)
            item.reviewCount = reviewCountAggregated

            let onlineRatingAggregated = this.itemsByAddress[addressId].reduce((a, b) => {
              return a + (b.onlineRating ? (b.onlineRating / 5) * b.reviewCount : 0)
            }, 0)
            item.onlineRating = 5 * (onlineRatingAggregated / reviewCountAggregated)

            items.push(item)
          }
        }

        // Summary
        let surveysCompletedSummary = items.reduce((a, b) => {
          return a + b.surveysCompleted
        }, 0)

        let surveysCompletedWithSatisfaction = items.reduce((a, b) => {
          return a + (b.satisfaction ? b.surveysCompleted : 0)
        }, 0)

        let surveysCompletedWithResponseRate = items.reduce((a, b) => {
          return a + (b.responseRate !== null ? b.surveysCompleted : 0)
        }, 0)

        let surveysCompletedWithResponseTime = items.reduce((a, b) => {
          return a + (b.responseTime ? b.surveysCompleted : 0)
        }, 0)

        let satisfactionSummary =
          5 *
          (items.reduce((a, b) => {
            return a + (b.satisfaction ? (b.satisfaction / 5) * b.surveysCompleted : 0)
          }, 0) /
            surveysCompletedWithSatisfaction)

        let responseRateSummary =
          100 *
          (items.reduce((a, b) => {
            return a + (b.responseRate !== null ? (b.responseRate / 100) * b.surveysCompleted : 0)
          }, 0) /
            surveysCompletedWithResponseRate)

        let responseTimeSummary =
          items.reduce((a, b) => {
            return a + (b.responseTime ? b.responseTime * b.surveysCompleted : 0)
          }, 0) / surveysCompletedWithResponseTime

        let reviewCountSummary = items.reduce((a, b) => {
          return a + (b.reviewCount ? b.reviewCount : 0)
        }, 0)

        let onlineRatingSummary =
          5 *
          (items.reduce((a, b) => {
            return a + (b.onlineRating && b.reviewCount ? (b.onlineRating / 5) * b.reviewCount : 0)
          }, 0) /
            reviewCountSummary)

        this.satisfactionTop3 = [...new Set(items.map((item) => item.satisfaction))]
          .filter((item) => !isNaN(item) && item)
          .sort((a, b) => b - a)
          .slice(0, 3)
        this.responseRateTop3 = [...new Set(items.map((item) => item.responseRate))]
          .filter((item) => !isNaN(item) && item)
          .sort((a, b) => b - a)
          .slice(0, 3)
        this.responseTimeTop3 = [...new Set(items.map((item) => item.responseTime))]
          .filter((item) => !isNaN(item) && item)
          .sort((a, b) => a - b)
          .slice(0, 3)
        ;[
          ['satisfaction', this.satisfactionTop3[0]],
          ['responseRate', this.responseRateTop3[0]],
          ['responseTime', this.responseTimeTop3[0]],
        ].forEach(([key, topScore]) => {
          this.$set(
            this.highscores,
            key,
            items.filter((i) => i[key] === topScore)
          )
        })
        this.items = items
        this.overall = {
          name: ['Overall'],
          surveysCompleted: [surveysCompletedSummary],
          onlineRating: [this.formatRating(onlineRatingSummary), reviewCountSummary],
          satisfaction: [this.formatRating(satisfactionSummary)],
          responseRate: [this.formatRate(responseRateSummary)],
          responseTime: [this.formatResponseTime(responseTimeSummary)],
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.status = 'loaded'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.gold {
  color: #d7a032;
}
.silver {
  color: #687a88;
}
.bronze {
  color: #b5603b;
}
.card-body {
  padding: 16px !important;
}
.highscore-icon {
  font-size: 25px;
  line-height: 1;
  margin: 5px;
}
::v-deep .table-responsive {
  margin: 0;
}
::v-deep .table-sm td,
.footer-th {
  font-size: 14px;
}
::v-deep tr > [aria-sort] {
  background-image: none !important;
}

::v-deep .agg-table-header {
  position: fixed;
  z-index: -1;
}

::v-deep .agg-table-footer {
  display: none;
}

.text-truncate:hover {
  overflow: visible !important;
  text-overflow: none !important;
  background-color: white !important;
  position: absolute !important;
  z-index: 100;
}
</style>
