import { cloneDeep } from 'lodash-es'
import cubejs from '@cubejs-client/core'
import UserService from '../services/UserService'
import jwt from 'jsonwebtoken'
import store from '@/store'

const mutexObj = {}

let apiTokenPromise
const getAuthToken = async () => {
  // const {
  //   body: { user },
  // } = await UserService.get()
  const u = {
    // userId: user._id,
  }
  return jwt.sign({ u }, store.getters.selectConfigByName('CUBE_CLOUD_API_SECRET'), {
    expiresIn: '1d',
  })
}

let cubejsApi = null

const initCubeJS = () => {
  if (!cubejsApi) {
    cubejsApi = cubejs(
      () => {
        if (!apiTokenPromise) {
          apiTokenPromise = getAuthToken()
        }
        return apiTokenPromise
      },
      { apiUrl: store.getters.selectConfigByName('CUBE_CLOUD_API_URL') }
    )
  }

  return cubejsApi
}

export default initCubeJS
export const load = ({ mutexKey = 'query', ...query }) => {
  if (!cubejsApi) {
    initCubeJS()
  }
  return cubejsApi.load(query, { mutexObj, mutexKey })
}

function getDefaultDayparts() {
  const daypart = {
    morning: { start: '12:00 AM', end: '11:59 AM' },
    afternoon: { start: '12:00 PM', end: '4:59 PM' },
    evening: { start: '5:00 PM', end: '11:59 PM' },
  }
  return daypart
}
export const defaultDayparts = getDefaultDayparts()
export async function loadMetrics(queries) {
  const resultSets = await Promise.all(queries.map(load))
  const metrics = []

  resultSets.forEach((r) => {
    const series = r.series()

    if (series.length) {
      series.forEach((s) => {
        metrics.push({ key: s.key, value: r.totalRow()[s.key] })
      })
    } else {
      r.query().measures.forEach((m) => metrics.push({ key: m, value: 0 }))
    }
  })

  return metrics
}

export async function loadCompareMetric(query, opts = {}) {
  const resultSet = await cubejsApi.load(query, opts)
  return resultSet.decompose().map((r) => r.rawData())
}

export async function loadMetric({ mutexKey = 'query', ...query }) {
  const resultSet = await cubejsApi.load(query, { mutexObj, mutexKey })
  return resultSet.rawData()
}

export async function loadSeries(queries, opts = {}) {
  const resultSets = await Promise.all(queries.map(load))
  return resultSets
}
