<template>
  <div>
    <!-- header -->
    <div class="mb-4 header chart-nav">
      <div class="container-fluid">
        <div class="header-body py-3">
          <div class="row align-items-end">
            <div class="col-lg-2">
              <h1 class="header-title main-header mt-2">Leaderboard</h1>
            </div>
            <div class="col">
              <div class="mr-4">
                <h6 class="header-pretitle mt-3">Date range</h6>
                <div class="d-flex align-items-center flex-wrap">
                  <DateRangePicker :minDate="earliestStartDate" @change="onDateRangeChange" />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-inline-block mr-3 mb-1">
                <button
                  class="btn btn-outline-primary"
                  @click="verifyExport()"
                  id="ExportMenuButton"
                >
                  <span class="fe fe-download"></span> Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <!-- locations -->
      <LocationsSection :filters="filters" :locations="locations" ref="locationsSection" />
      <hr class="mt-0 mb-4" />
      <!-- employees -->
      <EmployeesSection
        v-if="activeCompany._id != 'ALL_COMPANIES'"
        :filters="filters"
        :locations="locations"
        :activeCompanyId="activeCompany._id"
        ref="employeesSection"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LocationsSection from './LocationsSection'
import EmployeesSection from './EmployeesSection'
import LocationService from '@/services/LocationService'
import DateRangePicker from '@/components/Common/DateRangePicker'
import LeaderboardBase from './LeaderboardBase'
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')
const LocationModule = createNamespacedHelpers('location')

// @group Leaderboard
// This component is the main layout for the leaderboard page.
export default {
  name: 'Leaderboard',

  extends: LeaderboardBase,

  components: {
    LocationsSection,
    EmployeesSection,
    DateRangePicker,
  },

  data() {
    return {
      selectedDateRange: 'Last 30 days',
      locations: [],
    }
  },

  computed: {
    ...mapGetters('company', ['activeCompany', 'companies']),
    ...LocationModule.mapGetters(['userActiveCompanyLocations']),
    ...UserModule.mapGetters(['onlineUser']),
    earliestStartDate() {
      return this.activeCompany.created
    },
    filters() {
      return {
        dateRange: this.selectedDateRange,
        timezone: this.$moment.tz.guess(),
        companyIds:
          this.activeCompany._id == 'ALL_COMPANIES'
            ? this.companies.map((company) => company._id)
            : [this.activeCompany._id],
      }
    },
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      async handler() {
        const result = await LocationService.getLeaderboardLocations({
          companyIds: this.filters.companyIds,
        })
        this.locations = result.data.data.locations
          .filter((l) => l.hideFromReports !== true)
          .sort((a, b) => {
            a.name > b.name
          })
        if (this.activeCompany.isolateLocations && this.onlineUser.role !== 'admin') {
          this.locations = this.locations.filter((i) => this.onlineUser.locations.includes(i._id))
        }
      },
    },
  },

  methods: {
    // @vuese
    // Displays modal verifying the user wants to export with the current filters.
    async verifyExport() {
      const message = `
        A CSV file will be created with the data from both the locations and employees
        tables for the current selected date range as is currently displayed.
        `
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Export' } }),
      ])
      let exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Export',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) {
        this.exportLeaderboard()
      }
    },

    // @vuese
    // Downloads the generated CSV of the current leaderboard data
    exportLeaderboard() {
      try {
        const empSectionData = this.$refs.employeesSection.items
        const locSectionData = this.$refs.locationsSection.items
        const columnATitle = 'Location'
        const csv = this.generateCSV(locSectionData, empSectionData, columnATitle)

        let fileURL = window.URL.createObjectURL(new Blob([csv]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Leaderboard.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()
      } catch (error) {
        this.$notify({
          type: 'error',
          duration: 5000,
          title: `Exporting Leaderboard data failed! Try again`,
        })
      }
    },

    // @vuese
    // Creates a CSV based off the current leaderboard data
    // @arg locations: `Object`, employees: `Object`, RETURNS: `Array` csv
    generateCSV(locations, employees, columnATitle) {
      let csv = [
        this.locationsCSVSection(locations, columnATitle),
        this.employeesCSVSection(employees),
      ].join('\r\n\n')
      return csv
    },

    // @vuese
    // Creates a CSV section based off locations data in the leaderboard
    // @arg locations: `Object`, RETURNS: `Array`
    locationsCSVSection(locations, columnATitle) {
      let csvRows = []
      const header = [
        columnATitle,
        'Surveys Completed',
        'Online Rating',
        'Satisfaction',
        'Response Rate',
        'Response Time',
      ]
      locations.forEach((loc) => {
        let locCols = []
        locCols.push(`"${loc.name || ''}"`)
        locCols.push(loc.surveysCompleted || '')
        locCols.push(this.formatRating(loc.onlineRating) || '')
        locCols.push(this.formatRating(loc.satisfaction) || '')
        locCols.push(this.formatRate(loc.responseRate) || '')
        locCols.push(this.formatResponseTime(loc.responseTime) || '')
        csvRows.push(locCols.join(','))
      })
      return [header.join(','), ...csvRows].join('\r\n')
    },

    // @vuese
    // Creates a CSV section based off employees data in the leaderboard
    // @arg employees: `Object`, RETURNS: `Array`
    employeesCSVSection(employees) {
      let csvRows = []
      const header = ['Name', 'Locations', 'Open Rate', 'Response Rate', 'Response Time']
      employees.forEach((emp) => {
        let empCols = []
        empCols.push(emp.name || '')

        if (!emp.locations.length) {
          empCols.push('All locations')
        } else {
          let locList = []
          emp.locations.forEach((loc) => {
            locList.push(loc)
          })
          empCols.push(`"${locList.join('; ')}"`)
        }

        empCols.push(this.formatRate(emp.openRate) || '')
        empCols.push(this.formatRate(emp.responseRate) || '')
        empCols.push(this.formatResponseTime(emp.responseTime) || '')
        csvRows.push(empCols.join(','))
      })
      return [header.join(','), ...csvRows].join('\r\n')
    },
    onDateRangeChange(dateRange) {
      this.selectedDateRange = dateRange
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-nav {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #f9fbfd;
}

@media (max-width: 800px) {
  .main-header {
    margin-bottom: 5px;
  }
}
</style>
