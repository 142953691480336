import moment from 'moment'
export function formatSeries(resultSet) {
  if (!resultSet) {
    return [{ data: [] }]
  }

  const mapName = (title) => {
    switch (title) {
      case 'Analyticevents Customer Added':
        return 'Customers Added'
      default:
        return title.replace(/^Surveys\s+/, '')
    }
  }

  return resultSet.series().map((s) => ({
    name: mapName(s.title),
    data: s.series.map((r) => ({ x: r.x, y: r.value })),
  }))
}

export function formatPivotTable(resultSet) {
  const pivotConfig = {
    x: ['Locations.displayName'],
    y: ['measures'],
    fillMissingDates: true,
    joinDateRange: false,
  }

  // columns: resultSet.tableColumns(pivotConfig),
  return resultSet.tablePivot(pivotConfig)
}

export function formatSatisfactionDayparts(resultSets) {
  if (!resultSets.length) {
    return [{ data: [] }]
  }

  const format = []

  resultSets.forEach((s, i) => {
    const [series] = s.series()

    const sery = {
      name: { 0: 'Morning', 1: 'Afternoon', 2: 'Evening', 3: 'Surveys Completed' }[i],
      type: i === 3 ? 'line' : 'column',
      data: [0, 0, 0, 0, 0, 0, 0],
    }
    if (series) {
      const dataMap = series.series.reduce((acc, cur) => ({ ...acc, [cur.x]: cur.value || 0 }), {})
      sery.data = sery.data.map((day, i) => (dataMap[i] != null ? dataMap[i] : day))
    }
    format.push(sery)
  })

  return format
}

export function formatSatisfactionSources(resultSet) {
  const series = resultSet.series()

  if (!series.length) {
    return []
  }

  return series.map((s) => ({
    name: s.title,
    data: s.series.filter((r) => r.x !== '[Empty string]').map((r) => ({ x: r.x, y: r.value })),
  }))
}

export function formatDonut(resultSet) {
  const labels = []
  const series = []
  if (!resultSet || !resultSet.series().length) {
    return { labels, series }
  }

  for (const { x, value } of resultSet.series()[0].series) {
    if (x === '[Empty string]') continue
    labels.push(x)
    series.push(value)
  }
  return { labels, series }
}

export function formatOverTimeReviews(resultSet) {
  if (!resultSet) {
    return [{ data: [] }]
  }

  const mapName = (title) => {
    switch (title) {
      case 'Reviews Avg Rating':
        return 'Rating'
      case 'Reviews Count':
        return 'Reviews'
      default:
        return title
    }
  }

  return resultSet.series().map((s) => ({
    name: mapName(s.title),
    type: s.title === 'Reviews Count' ? 'line' : 'column',
    data: s.series.map((r) => ({ x: r.x, y: r.value })),
  }))
}

export const formatCount = (value = 0) => value.toFixed(0).replace(/\B(?=(\d{3})+\b)/g, ',')
export const formatAvg = (value = 0) => (value ? value.toFixed(1) : '-')
export const formatPercent = (value) => (Number.isInteger(value) ? value : value.toFixed(1)) + ' %'

export const formatPercentDiff = (before, after) =>
  after ? formatPercent(((after - before) / before) * 100) : ''
