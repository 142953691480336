<template>
  <span>
    <i v-if="!value" class="fe fe-x text-muted text-faded"></i>
    <i v-else class="fe fe-check-circle text-success"></i>
  </span>
</template>

<script>
export default {
  name: 'CheckOrX',
  props: ['value'],
}
</script>
