<template>
  <MainContent>
    <Leaderboard :include-header="false" v-if="activeCompany" :key="activeCompany._id" />
  </MainContent>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContent from '@/components/MainContent/MainContent'
import Leaderboard from '@/components/Modules/Company/Leaderboard/Leaderboard'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'LeaderboardPage',

  components: {
    MainContent,
    Leaderboard,
  },
  mounted() {
    trackEvent(this.$intercom, 'Leaderboard')
  },
  computed: {
    ...mapGetters('company', ['activeCompany']),
  },
}
</script>

<style scoped lang="scss"></style>
