<template>
  <Vue2DateRangePicker
    autoApply
    ref="picker"
    opens="right"
    showDropdowns
    :ranges="ranges"
    :minDate="minDate"
    :maxDate="maxDate"
    v-model="selectedRange"
    singleDatePicker="range"
    :key="minDate"
  >
    <!-- input -->
    <template v-slot:input="i">
      <i class="fe fe-calendar mr-2 text-muted"></i>
      <span class="text-secondary" style="font-size: 14px"
        >{{ formatInput(i.startDate) }} - {{ formatInput(i.endDate) }}</span
      >
    </template>
    <!-- ranges -->
    <template #ranges="r">
      <div class="ranges">
        <ul>
          <li
            v-for="([start, end], name) in r.ranges"
            :key="name"
            @click="r.clickRange([start, end])"
          >
            <span class="mr-2 name">{{ name }}</span>
            <small class="text-muted">{{ formatRanges(start) }} - {{ formatRanges(end) }}</small>
          </li>
        </ul>
      </div>
    </template>
  </Vue2DateRangePicker>
</template>

<script>
import Vue2DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'DateRangePicker',
  components: {
    Vue2DateRangePicker,
  },
  props: ['minDate', 'dateRange'],
  data() {
    const today = this.$moment().toDate()
    const tomorrow = this.$moment().add(1, 'day').startOf('day').toDate()
    const endOfToday = this.$moment().endOf('day').toDate()
    const yesterday = this.$moment().subtract(1, 'day').toDate()
    const last30Days = [this.$moment().subtract(30, 'days').toDate(), tomorrow]
    const minDate = this.$moment(this.minDate).toDate()

    return {
      maxDate: this.$moment().endOf('month').add(1, 'day').toDate(),
      selectedRange: {
        startDate: last30Days[0],
        endDate: last30Days[1],
      },
      ranges: {
        Today: [today, tomorrow],
        'This week': [this.$moment().startOf('week').add(1, 'day').toDate(), tomorrow],
        'This month': [this.$moment().startOf('month').toDate(), tomorrow],
        'Last 30 days': last30Days,
        'Last 365 days': [this.$moment().subtract(365, 'days').toDate(), tomorrow],
        'All time': [minDate, tomorrow],
      },
    }
  },
  watch: {
    selectedRange({ startDate, endDate }) {
      const dateRange = [this.formatQuery(startDate), this.formatQuery(endDate)]
      this.$emit('change', dateRange)
    },
    dateRange(range) {
      if (Array.isArray(range)) {
        this.selectedRange = {
          startDate: this.$moment(range[0], 'YYYY-MM-DD').toDate(),
          endDate: this.$moment(range[1], 'YYYY-MM-DD').toDate(),
        }
      } else if (range in ranges) {
        this.selectedRange = ranges[range]
      }
    },
  },
  methods: {
    formatInput(date) {
      return this.$moment(date).format('MMM D, YYYY')
    },
    formatRanges(date) {
      return this.$moment(date).format(`MMM D, YY`)
    },
    formatQuery(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .reportrange-text {
  border: 1px solid #edf2f9;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
::v-deep .daterangepicker {
  min-width: 100% !important;
  border: 1px solid #edf2f9;
  border-radius: 5px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  &:before {
    border-bottom: 0;
  }

  .calendars {
    display: flex;
    flex-wrap: nowrap;
  }

  .ranges {
    width: max-content;

    li {
      color: var(--secondary);
      padding: 12px 15px;

      .name {
        font-size: 14px;
      }

      &:hover {
        color: var(--primary);
        background-color: var(--light);
      }
    }
  }
  .drp-calendar {
    border-left: 1px solid var(--light) !important;
  }
  .calendar-table {
    table {
      border-collapse: separate;
      border-spacing: 2px;
    }
    th {
      font-weight: normal;
      color: var(--secondary);
    }
    td {
      color: var(--muted);

      &.in-range {
        background-color: #e9f0fa;
      }
      &.active {
        color: #fff !important;
        background-color: var(--primary) !important;
      }
    }
  }
  select.monthselect,
  input.yearselect {
    font-size: 14px;
    color: var(--dark);
  }
  // .next > span,
  // .prev > span {
  //   border: solid var(--secondary);
  //   border-width: 0 2px 2px 0;
  // }
}
</style>
